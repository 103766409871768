@import "../../../styles/flex";

.home {
	@include flex(column, flex-start, center);
	width: 100%;

	.box {
		margin-top: 1rem;
		background-color: rgb(229, 229, 229);
		padding: 1rem 2.5rem;
		border-radius: 2rem;
	}

}
